import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import {Button, Chip} from "@mui/material";

interface FeaturedPostProps {
    post: {
        date: string;
        description: string;
        image: string;
        imageLabel: string;
        title: string;
    };
}

export default function FeaturedPost(props: FeaturedPostProps) {
    const { post } = props;

    return (
        <Grid item xs={12} md={6}>
            <CardActionArea component="a" href="#">
                <Card sx={{ display: 'flex' }}>
                    <CardContent sx={{ flex: 1 }}>
                        <Typography component="h2" variant="h5">
                            {post.title} <Chip label="已报名" color="success" />
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary"  paragraph>
                            {post.date}
                        </Typography>
                        <Typography variant="subtitle1" paragraph>
                            人数 2/300
                        </Typography>
                        <Button color="primary">
                            查看详细信息/报名
                        </Button>
                    </CardContent>
                    {post.image===''?<></>:(
                        <CardMedia
                            component="img"
                            sx={{ width: 160, display: { xs: 'none', sm: 'block' } }}
                            image={post.image}
                            alt={post.imageLabel}
                        />
                    )}
                </Card>
            </CardActionArea>
        </Grid>
    );
}