import {Grid, Typography} from "@mui/material";
import Chart from "./Chart";
import Deposits from "./Deposits";
import Orders from "./Orders";
import {useState} from "react";

export default function (){
    const [x] = useState("cnm")
    return (
        <>
            <Grid container spacing={3}>
                {/* Chart */}
                <Grid item xs={12} md={8} lg={9}>
                    <Typography
                        sx={{
                            bgcolor: 'background.paper',
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 240,
                        }}
                    >
                        <Chart/>
                    </Typography>
                </Grid>
                {/* Recent Deposits */}
                <Grid item xs={12} md={4} lg={3}>
                    <Typography
                        sx={{
                            bgcolor: 'background.paper',
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 240,
                        }}
                    >
                        <Deposits/>
                    </Typography>
                </Grid>
                {/* Recent Orders */}
                <Grid item xs={12}>
                    <Typography sx={{ bgcolor: 'background.paper',p: 2, display: 'flex', flexDirection: 'column'}}>

                        <Orders/>
                    </Typography>
                </Grid>
            </Grid>
        </>
    );

}