import * as React from 'react';
import {useState} from "react";
import { useSpring, animated } from 'react-spring';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import PcHeader from '../../page/app/index/PCHead';

import {Box, ImageList, ImageListItem, CssBaseline,Typography} from "@mui/material";
import PhoneIndex from "../../page/app/index/PhoneIndex";

export const sections = [
    {title: 'OJ', url: 'https://acm.likole.com/',src:'./icon/acm.png'},
    {title: '招新平台', url: 'https://acm.likole.com/#/imudges/recruit',src:'./icon/join.png'},
    {title: '新闻', url: 'https://news.imudges.com/',src:'./icon/join.png'},
];

const IMU={
    tags:'关于联盟',
    title:'IMUDGES',
    detail:`Inner Mongolia University Developer Group of Elite Student,
    IMUDGES 成立于2011年，是由一群喜欢钻研、勤于动手、勇于创新的学生组成的一个学生组织。
    联盟以“自由、创新、共享”为宗旨，希望为“学生”、“学校”、“企业”提供更好的合作平台。`
}
const imgList = [
    {tags:'场地',title:'奖状墙',detail:'近年来联盟成员获得奖项的展示',url:'./homeImg/7.jpg',author:'lfs'},
    {tags:'活动',title:'授课',detail:'',url:'./homeImg/5.JPG',author:'lfs'},
    {tags:'活动 [2021-8-31]',title:'暑期开发合影',detail:'成功开发一个项目不仅能够大幅度提高自身的编程能力，同时也能够提升工程、团队协作能力。而暑期开发就是联盟为每个团队提供的优秀开发平台，也是每位联盟核心成员的必经之路。',url:'./homeImg/2.jpg',author:'lfs'},
    {tags:'活动',title:'团建活动合影',detail:'',url:'./homeImg/3.jpg',author:'lfs'},
    {tags:'比赛获奖',title:'工位',detail:'',url:'./homeImg/6.JPG',author:'lfs'},
    {tags:'比赛获奖',title:'华为AI竞赛合影',detail:'',url:'./homeImg/1.jpg',author:'lfs'},
    //{tags:'比赛获奖',title:'华北杯2',detail:'2',url:'./homeImg/2.jpg',author:'lfs'},
    {tags:'比赛获奖',title:'华北五省参赛合影',detail:'',url:'./homeImg/4.jpg',author:'lfs'},
    //{tags:'比赛获奖',title:'团建活动',detail:'',url:'./homeImg/3.jpg',author:'lfs'},
]
const theme = createTheme();
export default function (props: any) {
    const [title,setTitle] = useState(IMU.title);
    const [tags,setTags] = useState(IMU.tags);
    const [detail,setDetail] = useState(IMU.detail)
    const [imgScale,setImgScale]=useState(new Array(imgList.length).fill('scale(1,1)'))
    const [textDisplay,setTextDisplay] = useState(new Array(imgList.length).fill('block'))
    //动画
    const initialStyle = useSpring({
        config:{
            duration:750,
        },
        from:{
            height:'100vh',
            width:'100vw',
            backgroundColor:'black',
            zIndex:100,
        },
        to:{ height:'0', },
    })
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Box
                position={'relative'}
                sx={{display: 'flex',
                    flexDirection: 'row',
                    alignItems:'center',
                    justifyContent:'center',
                    minHeight: '100vh',
                    width:'100vw',
                }}
            >
                {/*上面的box与下面一行是为了开屏动画，没有其他作用，此处结构看不懂联系QQ2213949483*/}
                <animated.div style={initialStyle}></animated.div>
                <Box className={'xsScreenContainer'}
                    sx={{
                    position:'absolute',
                    top:'0',
                    display: {md:'flex',xs:'none'},
                    minHeight: '100vh',
                    width:'100vw',
                }}>
                    <Box className={'leftContainer'} sx={{width:'50vw', ml:'5vw'}}>
                        <Box className={'header'} sx={{
                            mt:'10vh',
                            mb:'17vh'
                        }}>
                            <PcHeader sections={sections}></PcHeader>
                        </Box>

                        <Box className={'main'} sx={{
                            fontWeight: 600,
                        }}>
                            <Box sx={{
                                fontWeight:600,
                                mb:2,
                            }}>
                                {tags}
                                <Box sx={{
                                    mt:2,
                                    width:"2vw",
                                    height:'0.3vh',
                                    backgroundColor:'black'
                                }}></Box>
                            </Box>
                            <Box sx={{
                                fontWeight:500,
                                mb:2,
                                fontSize:'9vh',
                                maxWidth:'40vw',
                                wordBreak:'break-all'
                                // whiteSpace:'pre-wrap'
                            }}>{title}</Box>
                            <Box sx={{
                                fontWeight:500,
                                color:'#A9A9A9',
                                mb:2,
                                maxWidth:'40vw'
                            }}>{detail}</Box>
                        </Box>
                    </Box>

                    <Box className={'rightContainer'} sx={{
                        width:'50vw',
                        height:'100vh',
                        display:"flex",
                        flexWrap:'wrap',
                        overflow:'auto'
                    }}
                    onMouseOut={()=>{
                        setDetail(IMU.detail)
                        setTags(IMU.tags)
                        setTitle(IMU.title)
                    }}>
                        <ImageList sx={{ width:'50vw',m:0}} cols={2} gap={0}>
                            {imgList.map((item,index) => (
                                <ImageListItem
                                    onMouseOver={()=>{
                                        var tempImg = imgScale
                                        tempImg[index] = 'scale(1.1,1.1)'
                                        setImgScale(tempImg)
                                        var tempText = textDisplay
                                        tempText[index]= 'none'
                                        setTextDisplay(tempText)
                                    }}
                                    onMouseOut={()=>{
                                        var tempImg = imgScale
                                        tempImg[index] = 'scale(1,1)'
                                        setImgScale(tempImg)
                                        var tempText = textDisplay
                                        tempText[index]= 'block'
                                        setTextDisplay(tempText)
                                    }}
                                    key={index}
                                    sx={{overflow:"hidden",position:'relative',minHeight:'20vw'}}>
                                    <img
                                        style={{
                                            transform:imgScale[index],
                                            transition:'.6s',
                                        }}
                                        onMouseOver={()=>{

                                            setDetail(imgList[index].detail)
                                            setTags(imgList[index].tags)
                                            setTitle(imgList[index].title)
                                        }}
                                        src={`${item.url}`}
                                        alt={item.title}
                                        loading="lazy"
                                    />
                                    <Typography
                                        sx={{position:"absolute",bottom:'3vmin',left:'3vmin',
                                            fontWeight:600,fontSize:18, color:'white',
                                            display:textDisplay[index]}}>
                                        {item.title}
                                    </Typography>
                                </ImageListItem>
                            ))}
                        </ImageList>
                    </Box>

                </Box>
                <Box className={'xsScreenContainer'}
                     sx={{position:'absolute',
                         top:'0',
                         display: {md:'none',xs:'flex'},
                         minHeight: '100vh',
                         width:'100vw',
                }}>
                    <PhoneIndex></PhoneIndex>
                </Box>
            </Box>

        </ThemeProvider>
    );
}
