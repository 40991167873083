import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Copyright from "../../../shared/Copyright";
import {Link} from "@mui/material";
import {useHistory} from "react-router";


export default function() {
    const history = useHistory()
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            <CssBaseline />
            <Container component="main" sx={{ mt: 8, mb: 2 }} maxWidth="sm">
                <Typography variant="h2" component="h1" gutterBottom>
                    404-页面未找到
                </Typography>
                <Typography variant="h5" component="h2" gutterBottom>
                    {'您访问的页面不存在或者暂未开放'}
                </Typography>
                <Typography variant="body1">点击以回到<Link onClick={()=>history.push("/")}>主页</Link></Typography>
            </Container>
            <Box
                component="footer"
                sx={{
                    py: 3,
                    px: 2,
                    mt: 'auto'
                }}
            >
                <Copyright/>
            </Box>
        </Box>
    );
}