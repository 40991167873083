import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

function Copyright() {
    return (
        <Typography variant="body2" color="black">
            社团活动地点：内蒙古大学（北校区）计算机学院 214<br/>

            <Link color="inherit" href="">
                IMUDGES DEV TEAM {new Date().getFullYear()}.
            </Link>
        </Typography>
    );
}

interface FooterProps {
    title: string;
}

export default function Footer(props: FooterProps) {
    const {title} = props;

    return (
        <Box component="footer" sx={{py: 6}}>
            <Container maxWidth="lg">
                <Typography variant="h6" gutterBottom>
                    <Typography variant="body2" color="black">
                        {title}{' '}
                    </Typography>
                </Typography>
                <Copyright/>
            </Container>
        </Box>
    );
}