import {Fragment} from "react";
// import Markdown from "./NewsCard";
// const ReactDOM = require('react-dom')
import ReactMarkdown from 'react-markdown';
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import SendIcon from '@mui/icons-material/Send';
import './NewsCard.css'
interface CardDetail {
    post:{
        id:string,
        time:string,
        title:string,
        author:string,
        tags:string,
        description:string
    }
}
export default function NewsCard(props:CardDetail){
    const {post} = props
    var {id,title,time,author,tags,description} = post
    let markdown:string =  `## ${title} 
    ${time} by ${author}`
    let descriptionDetail:string[] = description.split(/\n|\s|\r/)
        .filter(item=>{
            return item!==''
        }).map(item=>{
        return `* ${item}`
    });
    return(
        <div className='NewsCard'>
            <div className='ToDetail'>
                <Button sx={{
                    top:'-50%',
                    left:'-50%',
                    width:'200px',
                    height:'50px'
                }}
                        variant="contained" endIcon={<SendIcon />}>查看详情</Button>
            </div>
            <div className='ShowDetail'>
                <Paper elevation={24}

                       sx={{
                           padding:3,
                           marginBottom:2,
                           position: 'relative',
                           backgroundColor: 'grey.800',
                           color: '#fff',
                           mb: 4,
                           backgroundSize: 'cover',
                           backgroundRepeat: 'no-repeat',
                           backgroundPosition: 'center',
                           backgroundImage: `url('https://source.unsplash.com/random')`,
                       }}>
                    <ReactMarkdown children={markdown}></ReactMarkdown>
                    {descriptionDetail.map(item=>{
                        return <ReactMarkdown children={item}></ReactMarkdown>
                    })}
                </Paper>
            </div>

        </div>
    )
}