import MainFeaturedPost from "./MainFeaturedPost";
import Grid from "@mui/material/Grid";
import FeaturedPost from "./FeaturedPost";
import * as React from "react";

const mainFeaturedPost = {
    title: '欢迎来到活动中心',
    description:
        "当前有3个活动正在报名，快来参与吧！",
    image: 'https://source.unsplash.com/random',
    imageText: 'main image description',
    linkText: 'Continue reading…',
};

const featuredPosts = [
    {
        title: '联盟招新活动',
        date: '2021.11.12 9:00-14:00',
        description:
            '新的一年、新的征程，联盟进行招新活动',
        image: '',
        imageLabel: 'Image Text',
    },
    {
        title: '联盟积分赛讲解',
        date: '2021.11.12 9:00-14:00',
        description:
            '包括积分赛内容',
        image: '',
        imageLabel: 'Image Text',
    },
];


export function ActivityIndexPage(){
    return (
        <>
            <MainFeaturedPost post={mainFeaturedPost} />
            <Grid container spacing={4}>
                {featuredPosts.map((post) => (
                    <FeaturedPost key={post.title} post={post} />
                ))}
            </Grid>
        </>
    )
}