import NewsCard from "./NewsCard";
const test1 = {
    id:'111',
    author:'lfs',
    title: '欢迎来到活动中心',
    description:`您好！这里是盟纪委监委督查组，经实地查看，发现你包联的核酸检测采样点存在工作人员防护、消杀不到位等问题，现在反馈给你，请立即做好整改，消除风险隐患。
    10月27日清晨6点，内蒙古自治区阿拉善盟纪委监委疫情防控专项督查组在阿拉善左旗某核酸检测采样点检查后，向该旗包联领导反馈检查结果。
    深秋时节，西北边陲的阿拉善盟夜晚寒气逼人。疫情发生后，从凌晨到深夜，从采样点到封闭社区，疫情防控工作开展到何时何处，纪检监察干部就跟进到哪里，持续强化监督检查，压紧压实疫情防控责任。`,
    time:'10-18',
    tags:'竞赛'
};
const test2 = {
    id:'111',
    author:'lfs',
    title: '欢迎来到活动中心',
    description:`sadj
asidha
asdhi`,
    time:'10-18',
    tags:'竞赛'
};
const test3 = {
    id:'111',
    author:'lfs',
    title: '欢迎来到活动中心',
    description:`salkhdask
    sakldasj
    dsakldjas
    asklldj`,
    time:'10-18',
    tags:'竞赛'
};

export default function News(){
    return(
        <>
            <NewsCard post={test1}></NewsCard>
            <NewsCard post={test2}></NewsCard>
            <NewsCard post={test3}></NewsCard>
        </>
    )
}