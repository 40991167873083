import {ReactElement} from "react";
import * as React from "react";
import {Dashboard} from "@mui/icons-material";
import AdminIndexPage from "../page/admin/index/AdminIndexPage";
import AdminPublishNewsPage from "../page/admin/publish/AdminPublishNewsPage";
import {RouteComponentProps} from "react-router";

export interface RouterObject {
    name:string,
    component:React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any> ,
    icon:React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>,
    path:String,//相对路径
    needPermission:Array<string>
    subRouter:Array<RouterObject>
}

export const AdminRouters:Array<RouterObject>=[
    {
        name:'主页',
        component:AdminIndexPage,
        icon:()=><Dashboard/>,
        needPermission:[],
        subRouter:[],
        path:"index"
    },

    {
        name:'发布新闻',
        component: AdminPublishNewsPage,
        icon:()=><Dashboard/>,
        needPermission:[],
        subRouter:[],
        path:"publish"
    }
]