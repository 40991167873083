import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';

import PhoneNav from './PhoneNav';
import {
    AppBar,
    Avatar,
    Container,
    CssBaseline,
    IconButton,
    Slide,
    Toolbar,
    Typography,
    useScrollTrigger
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
const introduce=()=>(
    <Box className={'main'} sx={{
        height:'34vh',fontWeight: 600,ml:'10vw',mt:'5vh'
    }}>
        <Box sx={{
            fontWeight:600,
            mb:2,
        }}>
            {IMU.tags}
            <Box sx={{
                mt:2,
                width:"2vw",
                height:'0.3vh',
                backgroundColor:'black'
            }}></Box>
        </Box>
        <Box sx={{
            fontWeight:500,
            mb:2,
            fontSize:'9vh',
            maxWidth:'60vw',
            wordBreak:'break-all'
            // whiteSpace:'pre-wrap'
        }}>{IMU.title}</Box>
        <Box sx={{
            fontWeight:500,
            color:'#A9A9A9',
            mb:2,
            maxWidth:'50vw'
        }}>{IMU.detail}</Box>
    </Box>
);
const IMU={
    tags:'关于联盟',
    title:'IMUDGES',
    detail:`Inner Mongolia University Developer Group of Elite Student,
    IMUDGES 成立于2011年，是由一群喜欢钻研、勤于动手、勇于创新的学生组成的一个学生组织。
    联盟以“自由、创新、共享”为宗旨，希望为“学生”、“学校”、“企业”提供更好的合作平台。`
};

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
    children: React.ReactElement;
}

function HideOnScroll(props: Props) {
    const {children, window} = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });
    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}


export default function PhoneIndex() {
    const [leftMenu,setMenu]=React.useState(false)

    return (
        <div>

                <React.Fragment>

                    <CssBaseline />
                    <HideOnScroll>
                        <AppBar sx={{backgroundColor:'white'}}>
                            <Toolbar>
                                <Box className={'head'} sx={{display:'flex',flexGrow:1,alignItems:'center'}}>
                                    <Avatar src={'./icon/favicon.png'} variant="rounded"></Avatar>
                                    <Typography
                                        sx={{
                                            color:'black',
                                            flexGrow:1,
                                            ml:'3vw',
                                        }}>
                                        IMUDGES
                                    </Typography>

                                    <IconButton
                                        size="large"
                                        edge="start"
                                        color="default"
                                        aria-label="open drawer"
                                        sx={{ mr: 2,flexGrow:0 }}
                                        onClick={()=>{setMenu(true)}}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                </Box>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Toolbar />
                    <Container>
                        {introduce()}
                    </Container>


                    <Drawer
                        anchor={'left'}
                        open={leftMenu}
                        onClose={()=>{setMenu(false)}}
                    >
                       <PhoneNav></PhoneNav>
                    </Drawer>
                </React.Fragment>
        </div>
    );
}


