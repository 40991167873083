import React from 'react';
import AppLayout from "./layout/app/AppLayout";
import {HashRouter, Redirect, Route, Switch} from "react-router-dom";
import {ActivityIndexPage} from "./page/app/activity/ActivityIndexPage";
import LoginPage from "./page/verify/LoginPage";
import AdminLayout from "./layout/admin/AdminLayout";
import {AdminRouters} from "./router/adminRouters";
import NewsPage from './page/news/NewsPage'
import NotFoundPage from "./page/common/NotFound/NotFoundPage";

function App() {
    return (
        <div>
            <HashRouter>
                <Switch>
                    <Redirect exact from="/" to="/home"/>
                    <Route path='/home'><AppLayout></AppLayout></Route>

                    <Route exact path="/page/activity" component={ActivityIndexPage}/>
                    <Route exact path="/page/news" component={NewsPage}/>

                    <Route path="/admin">
                        <Redirect exact from="/admin" to="/admin/index"/>
                        <AdminLayout>
                            {AdminRouters.map(n=><Route exact component={n.component} path={"/admin/"+n.path}/>)}
                        </AdminLayout>
                    </Route>

                    <Route path="/verify">
                        <Route exact path="/verify/login" component={LoginPage}/>
                    </Route>
                    <Route path="*" component={NotFoundPage}/>
                </Switch>
            </HashRouter>
        </div>

    );
}

export default App;
