import * as React from 'react';
import { Typography, Box, Link} from "@mui/material";
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import {useState} from "react";



import {useHistory} from "react-router";


interface HeaderProps {
    sections:{
        title:string,
        url:string,
        src?:string,
        // active?:boolean
    }[]
}

export default function Header(props: HeaderProps) {

    const {sections} = props;
    const [menuHeight,setHeight]=useState(12)
    const [length,setlength]=useState(2)
    const [menuButtonLength,setMenuButtonLength]=useState(10)
    const [ojDisplay,setOjDisplay]=useState('flex')
    const history = useHistory()
    return (
        <React.Fragment>
            <Box
                sx={{
                    width:`${length*10}vw`,
                    overflow:'hidden',
                    transitionDuration:'1s',
                    transitionTimingFunction:'ease-out',
                    boxShadow: 2,
                    display:'flex',
                    flexDirection:'row',
                    fontWeight:600,
                    ':hover':{
                        cursor:'pointer'
                    },
                }}>
                <Box
                    //onClick={()=>history.push("/verify/login")}
                    sx={{
                        height:'12vh',
                        minWidth:'10vw',
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'center',
                        border:1,
                        borderRight:0,
                        borderColor: '#DCDCDC',
                        paddingBottom:'3px',
                        // fontFamily:'Lobster',
                        fontSize:22
                        // fontWeight: 'bold',
                        // fontFamily: 'Monospace'
                    }}>
                    {/*<Avatar variant="square" src="favicon.ico" sx={{mr: 2, width: "6vmin", height: "6vmin"}}/>*/}
                    IMUDGES
                </Box>
                <Box
                    onMouseOver={()=>{
                        setOjDisplay('none')
                        setMenuButtonLength(0)
                        setlength(sections.length+1)
                        setHeight(0)
                    }}
                    sx={{
                        height:`${menuHeight}vh`,
                        minWidth:  `${menuButtonLength}vw`,
                        maxWidth: `${menuButtonLength}vw`,
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'center',
                        border:1,
                        borderRight:0,
                        borderColor: '#DCDCDC',
                        paddingBottom:'3px',
                        // fontWeight: 'bold',
                        // fontFamily: 'Monospace'
                    }}>
                    {/*<Avatar variant="square" src="favicon.ico" sx={{mr: 2, width: "6vmin", height: "6vmin"}}/>*/}
                    <Box sx={{display:ojDisplay,alignItems:'center'}}>
                        <MenuRoundedIcon fontSize={"large"} sx={{ml:'0.2vw'}} />
                        <Box sx={{ml:'0.2vw',fontWeight:300,fontSize:18}}>MENU</Box>
                    </Box>
                </Box>
                <Box
                    onMouseLeave={()=>{
                        setOjDisplay('flex')
                        setMenuButtonLength(10)
                        setlength(2)
                        setHeight(12)
                    }}
                    sx={{ display:'flex',
                    flexDirection:'row',
                    fontWeight:600,
                    ':hover':{
                        cursor:'pointer'
                    }}}>
                    {sections.map(section=>(
                        <Box
                            sx={{
                                display:'flex',
                                alignItems:'center',
                                justifyContent:'center',
                                height:`12vh`,
                                width:'10vw',
                                border:1,
                                borderColor: '#DCDCDC',
                            }}>
                            <Box sx={{
                                height:`12vh`,
                                width:'10vw',
                                display:'flex',
                                alignItems:'center',
                                justifyContent:'center',
                                paddingBottom:'3px',
                                ':hover':{
                                    padding:0,
                                    borderBottom:3,
                                    borderColor:'black',
                                }
                            }}>
                                {/*<Avatar variant="square" src={section.src} sx={{ mr:1,width: "5vmin", height: "5vmin"}}/>*/}
                                <Link href={section.url} underline="none" sx={{
                                    color:'black'
                                }}>
                                    {section.title}
                                </Link>
                            </Box>
                        </Box>
                    ))}
                </Box>


            </Box>
        </React.Fragment>
    );
    /*

     */
}