import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Copyright from "../../shared/Copyright";


const theme = createTheme();

export default function LoginPage() {
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // eslint-disable-next-line no-console
        console.log({
            email: data.get('email'),
            password: data.get('password'),
        });
    };

    return (
        <ThemeProvider theme={theme}>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage: 'url(https://source.unsplash.com/random)',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            alignSelf: 'center',
                            alignContent: 'center',
                            minHeight: '60%'
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            IMUDGES 认证中心
                        </Typography>

                        <Button
                            fullWidth
                            sx={{ mt: 25, mb: 5 }}
                            size="large"
                            variant="outlined"
                            disabled={true}
                        >
                            通过网关 登陆/注册（尚未开放）
                        </Button>

                        <Grid container>
                            <Grid item xs>
                                <Typography variant="body2">
                                    通过登录获得：
                                </Typography>

                                <Typography variant="body2">
                                    - 招新、OJ系统使用权限
                                </Typography>
                                <Typography variant="body2">
                                    - 活动中心创建、报名功能权限
                                </Typography>
                                <Typography variant="body2">
                                    - 自定义个人主页、子域名使用权限（开发中）
                                </Typography>
                            </Grid>
                        </Grid>


                    </Box>
                    <Copyright/>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}