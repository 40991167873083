import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import * as React from "react";
import {Link, Typography} from "@mui/material";
import {sections} from "../../../layout/app/AppLayout";

export default ()=>{
    return(
        <Box
            sx={{ width:'60vw'}}
            role="presentation"
        >
            <List>
                {sections.map((sec, index) => (
                    <ListItem button key={sec.title}>
                        <Link href={sec.url} underline="none" >
                            <ListItemIcon>
                                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                            </ListItemIcon>
                            <Link href={sec.url} underline="none" sx={{
                                color:'black'
                            }}>
                                {sec.title}
                            </Link>
                        </Link>
                    </ListItem>
                ))}
            </List>
            <Divider />
            <List>
                    <ListItem>
                        <Typography >使用PC端访问网站获得更好体验</Typography>
                    </ListItem>
            </List>
        </Box>
    );
}